import React from "react";

function StyledLink({
  text,
  url,
}) {
  return (
    <a className="styledLink" href={url} target="_blank" rel="noreferrer"> 
      {text}
    </a>
  )
}

export default StyledLink
