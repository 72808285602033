import * as React from 'react';

function BuyButtonComponent() {
  return (
    <div className="buyButton">
        <stripe-buy-button
            // prod
            buy-button-id="buy_btn_1Nd0UZIQbjonuS1lUgi8yZnE"
            publishable-key="pk_live_51NczMeIQbjonuS1lou84tg4F4DUrE0uitcVkEAtwVrtfrHTVxtX7MUgwiITOi6Kwvn5VjpNAxe8VvbhF9eHlvdnX000zhADMgS"
            // staging
            // buy-button-id="buy_btn_1Nd01UIQbjonuS1lHpmFLIgp"
            // publishable-key="pk_test_51NczMeIQbjonuS1lQuiWbiHQgXffCCxCOJnctHMLY76kv4kOGnxoBCbbLYtL9MgnhtafBsCuVSjRMm1SGh4N5ahs00leqtpIBp"
        >
        </stripe-buy-button>
    </div>
  );
}

export default BuyButtonComponent;
