import React from "react";

import info_image from "../assets/info_image.gif";
import header_image from "../assets/header_image.gif";
import william_wedding_party from "../assets/william_wedding_party.png";
import sarah_silcox_wedding_party from "../assets/sarah_silcox_wedding_party.png";
import claire_silcox_wedding_party from "../assets/claire_silcox_wedding_party.png";
import chris_silcox_wedding_party from "../assets/chris_silcox_wedding_party.png";
import robert_baskin_wedding_party from "../assets/robert_baskin_wedding_party.png";
import erik_polkowski_wedding_party from "../assets/erik_polkowski_wedding_party.png";
import elyse_t_wedding_party from "../assets/elyse_t_wedding_party.png";
import phoebe_rudnick_wedding_party from "../assets/phoebe_rudnick_wedding_party.png";
import rain_rudnick_wedding_party from "../assets/rain_rudnick_wedding_party.png";
import matthew_michonski_wedding_party from "../assets/matthew_michonski_wedding_party.png";
import claire_dodinval_wedding_party from "../assets/claire_dodinval_wedding_party.png";
import katherine_wedding_party from "../assets/katherine_wedding_party.png";
import Section from "./section";
import StyledLink from "./styledLink";
import BuyButton from "./buyButton";
import WeddingPartyBubble from "./weddingPartyBubble";

function Welcome() {
  return (
    <>
      {/*  */}
      {/* HEADER */}
      {/*  */}
      <Section collapsible={false}>
        <img
          alt="Katherine Marie Ventresca Masciopinto & William Stuart Rudnick"
          className="header_image"
          src={header_image}
        />
      </Section>

      {/*  */}
      {/* INFO */}
      {/*  */}
      <Section collapsible={false}>
        <img
          alt="October 28th, 2023. Chicago History Museum. 1601 N Clark St, Chicago, IL, 60614"
          className="header_image"
          src={info_image}
        />
      </Section>

      {/*  */}
      {/* EVENTS */}
      {/*  */}
      <Section
        collapsible={true}
        collapsed={true}
        header="Events 🍸"
      >
        <div>
          <strong>
            Friday, October 27th
          </strong>
          <li>8:30pm - 10:00pm: Welcome Party for out-of-town guests. Upstairs at <i>La Storia</i>, 1154 N. Dearborn St. Drinks, charcuterie and dessert will be served.</li>
        </div>
        <br />
        <div>
          <strong>
            Saturday, October 28th
          </strong>
          <li>5:30pm - 11:30pm: Ceremony & Jazz Reception at <i>The Chicago History Museum</i>, 1601 N. Clark St.</li>
          <li>12:00am - 3:00am: Afterparty at <i>The Hideout</i>, 1354 W. Wabansia Ave.</li>
        </div>
        <br />
        <div>
          <strong>
            Sunday, October 29th
          </strong>
          <li>10:30am - 12:00pm: Farewell bagels + lox from <i>Gotham Bagels</i> will be served in the <i>Groom's suite</i> at <i>The Ambassador</i>.</li>
        </div>
      </Section>

      {/*  */}
      {/* Travel */}
      {/*  */}
      <Section
        collapsible={true}
        collapsed={true}
        header="Travel 🚂"
      >
        <div>
          Chicago O'Hare is our recommended airport. It is about a ~45-60 minute drive from the airport to the Ambassador, or an ~hour by public transportation (via the Blue Line & 70 Bus on Division Street) 
        </div>
        <StyledLink
          url="https://www.transitchicago.com/blueline/"
          text="Blue Line"
        />
        <div>
          Chicago Midway is about a ~45 minute drive from Midway to the Ambassador, or a ~45 minute public transportation journey via the Orange Line & Red Line.
        </div>
        <StyledLink
          url="https://www.transitchicago.com/orangeline/"
          text="Orange Line"
        />
        <div>
          Those of you who know William and myself know that we prefer to travel by train whenever possible. Chicago's Union Station may be convenient for Amtrak travel depending where you live. Amtrak's website can be found here:
        </div>
        <StyledLink
          url="https://www.amtrak.com/home"
          text="Amtrak"
        />
        <br/>
        <div>
          <strong>Getting to the Ceremony</strong>
        </div>
        <br/>
        <div>
          The Chicago History museum is conveniently located at the corner of North Avenue and Clark Street.
        </div>
        <br/>
        <div>
          Whip out your Ventra card and hop on to your local bus or train to conveniently & affordably get to the wedding on time!
        </div>
        <br/>
        <div>
          <strong>North/South</strong>
          <li>9 Ashland Ave. Bus</li>
          <li>22 Clark St. Bus</li>
          <li>151 Sheridan Rd. Bus</li>
        </div>
        <br/>
        <div>
          <strong>East/West</strong>
          <li>72 North Ave. Bus</li>
        </div>
        <br/>
        <div>
          <strong>The "L"</strong>
          <li>The <i><b>Sedgwick</b></i> Brown Line station is 5 blocks from the museum.</li>
        </div>
        <br/>
        <div>
          If public transit is not an option, public parking is conveniently located one block north of the Museum near Clark and LaSalle streets at 1730 N. Stockton Drive. $10 with Museum validation.
        </div>
      </Section>

      {/*  */}
      {/* Hotel */}
      {/*  */}
      <Section
        collapsible={true}
        collapsed={true}
        header="Hotel 🏩"
      >
        <div>
          Our recommended hotel for family & friends is The Ambassador.
        </div>
        <StyledLink
          text="Ambassador"
          url="https://www.hyatt.com/en-US/hotel/illinois/ambassador-chicago/chija"
        />
        <div>
          It is ~5 blocks from The Chicago History Museum, and there will be a shuttle taking guests from The Ambassador Hotel to The Chicago History Museum on Saturday afternoon.
        </div>
        <br/>
        <div>
          The shuttle will also bring guests from the wedding venue back to the hotel at the end of the evening.
        </div>
        <br/>
        <div>
          The shuttle schedule will be posted here as soon as it is confirmed.
        </div>
      </Section>

      {/*  */}
      {/* Wedding Party */}
      {/*  */}
      <Section
        collapsible={true}
        collapsed={true}
        header="Wedding Party 🕺"
      >
        <div className="weddingPartyBubbles">
          <WeddingPartyBubble
            header_image={william_wedding_party}
            person="William Stuart Rudnick"
            pronouns="he/him"
            title="Groom"
          >
            <div>
              William was born on an (Insert Weather Here) Fall day on November 12th, 1995. William was immediately drawn to art, music, and thinking outside the box. William grew up in an environment where curiosity & creativity reigned. His parents, Katherine Stuart & Bill Rudnick, lovingly framed a childhood drawing of a sunflower that William drew when he was 5. This painting now hangs in a place of prominence in William & Katie’s house,  exploding off the page and demonstrating a youthful commitment to artistic exploration and not staying within the lines.
            </div>
            <br/>
            <div>
              William’s fondest childhood memories include Summer roadtrips to Colorado. As a young boy, William remembers marching off to Summer Camp as a Bennett Beaver at the YMCA of the Rockies in Estes Park, CO and, as he got older, long hikes in the hot summer Sun to the top of Mount Chapin, Chiquita, and Ypsilon with his siblings and numerous Bergman & Maher cousins. William also remembers spending time with his grandparents, Paul & Hope Rudnick, on the banks of the Roaring Fork River in Woody Creek, CO. These trips instilled a lifelong love of walking, biking, and being outside in the summers for William, and inspired William & Katie’s 2020 move to Denver.
            </div>
            <br/>
            <div>
              William remembers his Montessori education fondly, and appreciates the way that it set him up for a childhood where he was encouraged to push boundaries and explore the unknown around him. He was immediately drawn to the Montessori stations that revolved around music & math, early signs of his future career and passion for music. 
            </div>
            <br/>
            <div>
              William is grateful to his parents for instilling a lifelong love of questioning and searching for the truth within him. William remembers mornings at his childhood home, where his parents read the newspaper, drank coffee, and William and Rain roughhoused with their Dad. William holds tremendous gratitude to his Mom for guiding him through the sometimes turbulent waters of late childhood and early adolescence.
            </div>
            <br/>
            <div>
              In college, William applied his love of logic to his degree and future career in computer science. William found his true calling when writing code and playing music. As a self-taught guitarist and lyricist, William continues to create & write music under his stage name “Wicked Vic.” 
            </div>
            <br/>
            <div>
              William’s ideal Saturday includes a long walk to Lake Michigan with Katie and their sweet spotted dog Gus, a breakfast burrito or bagel (shoutout to Evette’s in Lincoln Park for the best breakfast wrap this side of the Mississippi!), and writing music as the long summer light wanes into twilight. 
            </div>
            <br/>
            <div>
              William fell in love with Katie because she likes to live the way that he does (though he admits to be more of a homebody than she), and he enjoyed cooking beside her in the kitchen more than most things in this life. He is looking forward to the simplicity of married life; cooking, cleaning, and building a life with one another. He is excited for Katie to be his sous chef for the rest of his life (except when cooking Italian food, when Katie gets to be the chef).
            </div>
            <br/>
            <div>
              Ask William about the most recent song he wrote, the latest copy of The Atlantic, or his latest & greatest recipe.
            </div>
            <br/>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={katherine_wedding_party}
            person="Katherine Marie Ventresca Masciopinto"
            pronouns="she/her"
            title="Bride"
          >
            <div>
              The bride, Katherine Marie Ventresca Masciopinto, was born in Highland Park, Illinois on a sunny First Day of Spring on March 21st, 1995. Born to two passionate teachers, Katie’s earliest memories include traveling to historical sites. When Katie was two, she proudly proclaimed from her stroller that Abraham Lincoln was the “greatest president” during a visit to Lincoln’s Springfield homestead. It is an opinion that she has held throughout her life.
            </div>
            <br/>
            <div>
              Katie’s grandparents were a formative force in her life growing up. She learnt about the beauty of an urban garden from her Grandpa Paul Masciopinto. She fondly remembers dancing to Artie Shaw records with her Grandpa Dante Linné Ventresca. She can see herself as a young girl, sitting next to her Grandma Marie Charlotte Ventresca (Pamaw Marie for short) during a long road trip — soaking up the family history & lore.
            </div>
            <br/>
            <div>
              Katie’s parents, Anne Louise Ventresca Masciopinto & Peter Paul Masciopinto, created an environment where each day felt like a classroom. Katie’s grateful to her parents for packing up the car each Summer and driving her out to California, where her “only childhood-dom” faded for a few blissful weeks spent with cousins by the sea.
            </div>
            <br/>
            <div>
              These experiences built a strong foundation for the many loves in Katie’s life. Katie’s favorite things include visiting National Park Sites, being a passenger on roadtrips, pasta, listening to Artie Shaw’s greatest hits, being near any body of water, and hosting friends for a dinner party with a historic theme.
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={rain_rudnick_wedding_party}
            person="Rain Rudnick"
            pronouns="they/them"
            title="Best Person"
          >
            <div>
              Rain currently lives in Berlin, where they spend their days making music, going on long walks with their dogs, Slava & Ruby, and developing software at Ableton. William's fondest memories of Rain include many childhood escapades jumping off stairs, jumping into piles of leaves, and generally getting bandaged & bruised as they pushed the boundaries of the physical world around them.
            </div>
            <br/>
            <div>
              More recently, William & Rain lived with one another in Chicago for a few months where they spent time sharing music, biking, and cooking with one another.
            </div>
            <br/>
            <div>
              William would like to thank Rain for being his first friend & mentor. William & Katie are very grateful to Rain for journeying from so far to be at our wedding.
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={claire_dodinval_wedding_party}
            person="Claire Hannah Dodinval"
            pronouns="she/her"
            title="Maid of Honor"
          >
            <div>
              Claire currently lives in the Logan Square neighborhood of Chicago. Claire is one of Katie's oldest friends from High School, where they first met in Yearbook class. On a typical Summer day in Chicago, one can find Claire strolling through the Farmer's Market, chatting with friends over a frozen Margarita at Estereo or a coffee at La Boulangerie, or traveling for a short trip to visit friends in Denver or fulfill work commitments in D.C.
            </div>
            <br/>
            <div>
              Ask Claire about environmental policy, sustainability, or her experiences studying abroad in New Zealand! Most recently, Claire & Katie traveled to Seattle where Claire introduced Katie to the beauty of the Pacific Northwest and they stumbled upon the heart of the Twilight fandom in Forks, Washington.
            </div>
            <br/>
            <div>
              Katie is grateful to Claire for her guidance throughout wedding planning, and for bringing joy & enthusiasm into the process.
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={robert_baskin_wedding_party}
            person="Robert Ford Baskin"
            pronouns="he/him"
            title="Groomsman"
          >
            <div>
              Robby to family, friends, and his students: William & Robby first met as babies because they are first cousins. Robby currently resides in New Haven, CT but will be moving to Boston this Summer to begin his PhD in English Literature at Boston College. William has many fond memories of visiting Estes Park, Colorado with Robby during family vacations in the summer, and William always looked forward to the engaging conversations that were sure to ensue when he was in Robby's presence as a kid.
            </div>
            <br/>
            <div>
              As William & Robby entered adulthood, their childhood banter transformed into an easy rapport given their shared interest in history & current events, and — above all — a shared sense of humor. Most recently, William & Katie visited Nantucket with Robby where they enjoyed visiting the Whaling Museum, eating Lobster Rolls, and discussing Herman Melville. William would like to share his gratitude to Robby for being his favorite improvisational comedian & for traveling from the East Coast to be a part of our day.
            </div>
            <br/>
            <div>
              Ask Robby about any & all things related to literature: Herman Melville, Henry James, H.P. Lovecraft, James Joyce, and Jane Austen are a few of Robby's favorite authors. 
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={elyse_t_wedding_party}
            person="Elyse Anne Tuennerman"
            pronouns="she/her"
            title="Bridesmaid"
          >
            <div>
              Elyse grew up in the rural hills of Pennsylvania & moved to Chicago in 2018, where she first met Katie when they were deskmates at the company they both still work for, dscout. Both relatively new employees, Elyse & Katie quickly discovered themselves to be kindred spirits with the gift of gab as they navigated their new lives in the city — enjoying concerts, fancy cocktails, and a book club with one another (Elyse was more diligent about reading the book).
            </div>
            <br/>
            <div>
              Elyse became an adopted Phoenician in 2020 and never looked back towards the bone-chilling Chicago winters, but Katie cherishes the moment she sees Elyse after a long-time away. Katie is always impressed by the way that she can jump back into dynamic conversations about life, history, and yes — work, with Elyse like no time has passed at all.
            </div>
            <br/>
            <div>
              Ask Elyse about architectural history, Frank Lloyd Wright, her experiences with bears, or her pets: Clara, Winston, and Laszlo. Katie would like to thank Elyse for being a faithful conversational companion these past many years, and for helping her find her way to the other side of her twenties with aplomb. 
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={matthew_michonski_wedding_party}
            person="Matthew Ronald Michonski"
            pronouns="he/him"
            title="Groomsman"
          >
            <div>
              Matt currently lives in Grand Rapids, Michigan — where he spends his days on the frontlines as a nurse. Matt is William's closest friend from college, where the two became fast friends sharing a love of climbing, music, and half-priced draft night. 
            </div>
            <br/>
            <div>
              Ask Matt about his historic apartment in Grand Rapids, his time learning about the king of Michgan's little known Beaver Island, or what he's currently reading. An avid outdoorsman, Matt introduced Will & Katie to their favorite camping spot — Nordhouse Dunes. To know Matt is to love Matt. William & Katie's time in Denver overlapped with Matt's for a few months, where they were able to spend long afternoons soaking up the sun at Cheeseman Park and frequenting the local watering holes.
            </div>
            <br/>
            <div>
              Most recently, William & Katie had the chance to visit Matt in Grand Rapids where they spent a wonderful afternoon wandering through the Gerald Ford Presidential Library & Museum. William would like to share his particular thanks to Matt for being one of his favorite people in the world. 
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={sarah_silcox_wedding_party}
            person="Sarah Elizabeth Silcox"
            pronouns="she/her"
            title="Bridesmaid"
          >
            <div>
              Sarah currently lives in Indianapolis, Indiana — and is one of Katie's cousins and first friends. When she was small, Katie called Sarah “Lala” and followed her cooler, older, cousin around like a lost puppy. As they got older, Sarah bestowed her wisdom on the younger generation; herding the Ventresca family cousins into the basement for “school” with “Mrs. Forbes” (a.k.a Sarah). Throughout High School & into College, Sarah filmed music videos & family events — her YouTube channel can be thought of as part of the family archives!
            </div>
            <br/>
            <div>
              Katie will be forever grateful to Sarah for shepherding her through her first year of College, taking her to her first college party, taking her home from her first college party, and helping her to avoid other college parties with more respectable wine & vinyl nights.
            </div>
            <br/>
            <div>
              Ask Sarah about her newsletter, the latest headliner at The Vogue theater, or her recent travels to San Diego or London! Katie would like to express her gratitude to Sarah for generously donating her time, guidance, and creativity at many “junctures in the road” throughout Katie's life, including this one. 
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={chris_silcox_wedding_party}
            person="Christopher Brent Silcox"
            pronouns="he/him"
            title="Groomsman"
          >
            <div>
              Hoosier by birth, Christopher has adopted sunny San Diego as his home. Born the day after Katie, Christopher & Katie grew up together as the “twins.” Katie & Christopher celebrated each birthday together as they were growing up (Sesame Street, Arthur, 101 Dalmatians to name a few of the themes), and Katie considers Christopher to be like a brother.
            </div>
            <br/>
            <div>
              Katie & Christopher attended Indiana University with one another, where Christopher took Katie under his wing and helped her adjust to college life. Christopher & Katie carried on the family tradition of DJ'ing on the college radio waves alongside their good friend Jack Stawick. Katie & William have been able to spend several weeks with Chris out in San Diego, where they experienced a once-in-a-lifetime camping trip to Pinnacles National Park together — stumbling upon a family of California Condors during a hike. 
            </div>
            <br/>
            <div>
              Ask Christopher about his new career in design, his most recent camping trip, or his recommendations when visiting San Diego. William would like to express his gratitude to Christopher for welcoming him into the family & standing up beside him.
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={claire_silcox_wedding_party}
            person="Claire Marie Silcox"
            pronouns="she/her"
            title="Bridesmaid"
          >
            <div>
              Claire is the most recent family member to join the Lincoln Park crew, where she spends her days helping folks find employment as a recruiter for LinkedIn. On a typical Saturday in Chicago, one may find Claire grabbing coffee at the Colectivo on Clark Street, visiting the Lincoln Park Conservatory, or sampling Tapas at Cafe Ba-Ba-Reeba.
            </div>
            <br/>
            <div>
              Claire is Katie's cousin, but — like so many of Katie's cousins, feels more like a sister. William and Katie have enjoyed the spontaneity that living in the same neighborhood as Claire has brought into their lives, as they look forward to near-weekly morning walks around North Pond with Claire.
            </div>
            <br/>
            <div>
              Ask Claire about career coaching & mentorship, how she's adjusting to life in Chicago, or her wonderful family! Katie is especially grateful to Claire for always asking about wedding planning & providing a listening ear throughout this process.
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={erik_polkowski_wedding_party}
            person="Erik Polkowski"
            pronouns="he/him"
            title="Best Man"
          >
            <div>
              Erik currently lives in the Albany Park neighborhood of Chicago with his dog, Empanada. Erik is one of William's oldest & dearest childhood friends. They first met in Art Class & immediately bonded over their childhood crushes on the same classmate. While their crush didn't go anywhere (they were 10), Erik & William have shared a lifelong love of comics, drawing, video games, biking, and green spaces.
            </div>
            <br/>
            <div>
              Most recently, Erik & William took a pottery class at the Lill Street Art Center together. Erik is known for being a wonderful conversationalist, ask him about the latest & greatest restaurant or coffee shop in or around his neighborhood, the beauty of the Brown Line, or the biking trip that he & William are going to start planning after the wedding. 
            </div>
            <br/>
            <div>
              William appreciates Erik's enthusiasm about all things, and is honored to call him Best Man. 
            </div>
          </WeddingPartyBubble>
          <WeddingPartyBubble
            header_image={phoebe_rudnick_wedding_party}
            person="Phoebe Elizabeth Rudnick"
            pronouns="she/her"
            title="Bridesmaid"
          >
            <div>
              Phoebe currently lives in the Lincoln Park neighborhood of Chicago. Phoebe is William & Rain's sister, though she has quickly become a fan favorite in the Ventresca Masciopinto family as well due to her open, empathetic, and warm spirit. Phoebe is the apple of Gus-the-dog's eye.
            </div>
            <br/>
            <div>
              Phoebe has an  impeccable sense of style. Ask Phoebe about her new career in the legal space, the most stylish dresses at Reformation, or the latest book that she's reading! One of William's earliest childhood memories involve feeding Phoebe a ladybug, but they've since made amends to become great friends. Some of William's fondest memories of Phoebe include visiting her at College in Boulder, Colorado, eating homemade Dal at Phoebe's studio on chilly winter nights in Chicago, and competing against her each morning when they do the NYT mini-crossword.
            </div>
            <br/>
            <div>
              William & Katie truly felt at home in Lincoln Park only after Phoebe moved to the neighborhood, and they want to thank her for all of her support and boundless patience throughout the wedding planning process.
            </div>
          </WeddingPartyBubble>
        </div>
      </Section>

      {/*  */}
      {/* REGISTRY */}
      {/*  */}
      <Section
        collapsible={true}
        collapsed={true}
        header="Registry 💐"
      >
        <div>
          Howdy! Thanks for stopping by our registry. We are registered at...
        </div> 
        <StyledLink
          text="Crate & Barrel"
          url="https://www.crateandbarrel.com/gift-registry/katherine-masciopinto-and-william-rudnick/r6554402"
        />
        <div>
          Though your presence and participation in our festivities is gift enough, we've registered for items that we're excited to incorporate into the family traditions that we will build together in the years to come. 
        </div>
        <br/>
        <div>
          We look forward to thinking of you each time we use one of these items. 
        </div>
        <br/>
        <div>
          Katie and I are also planning a honeymoon next April when we can both take time off work, if you would like to help contribute to our trip we would be eternally grateful 🙇🏼‍♀️❤️‍🔥🙇🏻 (hint - 🇯🇵🍣🍙🎏)
        </div>
        <BuyButton />
        <br/>
        <div>
          We are so grateful to you for celebrating with us throughout this journey, and we look forward to seeing you in October! 
        </div>
        <br/>
        <div>
          With gratitude, 
        </div>
        <div>
          Katie & William
        </div>
      </Section>

      {/*  */}
      {/* HISTORY */}
      {/*  */}
      <Section
        collapsible={true}
        collapsed={true}
        header="History 📔"
      >
        <div>
          One of the first non-indigenous settlers was Jean Baptiste Point du Sable, who built a home at the mouth of the Chicago River. 
        </div>
        <br/>
        <div>
          Fort Dearborn, the U.S. Army's westernmost outpost when it was built in 1803, sat beside the south bank of the Chicago River at the modern-day intersection of Michigan Avenue and Wacker Drive, near where Katie's office now stands.
        </div>
        <br/>
        <div>
          The fort was destroyed during the Battle of 1812, but a new settlement cropped up at the confluence of the many branches of the Chicago River at Wolf Point. It was at Wolf Point that the very settlers who we now remember through neighborhood and street names gathered, men such as Archibald Clybourn and John & Mark Noble. William's first apartment in Chicago was in Noble Square. The office of William's father, Bill Rudnick, now sits at the confluence of the rivers at Wolf Point. 
        </div>
        <br/>
        <div>
          Incorporated into a city in 1837, Chicago soon became a transportation hub due to its ideal location on Lake Michigan, not too far away from the banks of the Mississippi. 
        </div>
        <br/>
        <div>
          When the Galena & Chicago Railroad & the Illinois & Michigan Canal opened in 1848, Chicago transformed into a major international hub for the shipping of goods — helping to open the gates to the Western frontier. 
        </div>
        <br/>
        <div>
          As the economy flourished and the golden age of the railroad began, Chicago became a center for immigration. Many immigrants, notably of Irish, Swedish, Italian, and Jewish decent, arrived in Chicago yearning to breathe free. 
        </div>
        <br/>
        <div>
          Chicago blossomed into a hub of industry partly due to the rich bounty of its soil, as Illinois became an agrarian center for growing grain — oats, barley, rye, and corn filled the boxcars as they chugged across the plains. It is here that William & Katie's long threads of history begin to intertwine, as their families began a slow and steady march towards today. 
        </div>
        <br/>
        <div>
          William's great-grandparents, the O'Donnell's, tiled the soil on the confluence of Mississippi & Illinois rivers in Grafton, Illinois. One can imagine the early influences of these settlers on a young Don Bergman, William's grandfather, an expert outdoorsman and sailer.  One can imagine a young Don weaving his way through the rivers. 
        </div>
        <br/>
        <div>
          Katie's great-grandfather, Francesco Ventresca, made his way to Chicago from the hills of Abruzzi in Italy, splitting rails as a railroad worker across the United States. Francesco could not read or write English when he arrived, and he fondly recalled joining a 2nd grade class to learn. Upon his death in 1955, Francesco held a doctorate degree and spoke 5 languages. 
        </div>
        <br/>
        <div>
          The city of Chicago continued to grow. In 1900, 77% of the city's population were either foreign-born or born in the United States to foreign-born parentage. In 1910, William's great-grandfather, Morris Jerome “Marshall” Korshak, was born to Jewish immigrants who had fled Kiev, Ukraine. Marshall built a reputation and career as a leader of Chicago Democratic politics in the 1950's & 1960's. Upon his death in 1996, Marshall had held the titles of City Treasurer, Trustee of the Metropolitan Sanitary District, and State Senator. 
        </div>
        <br/>
        <div>
          The beginning of the 20th century brought Chicago into a new age of industry as World War I & World War II loomed, the working and professional classes in Chicago marched on. Katie's great-grandparents, Michael & Carmella Patano, completed their multi-year journey from Potenza, Italy to a quiet block at the intersection of 75th Court & George Street. Their sons, Louis & Nicholas Patano, built their brick workman's cottage. Michael drank wine with breakfast. Nicholas entered the army, driving a tank across Germany in the 5th Armored Division, 81st Tank Battalion.
        </div>
        <br/>
        <div>
          The soldiers returned home driving a post-war economic boom and a desire for new goods, services, and “cosmopolitan” experiences. The soldiers carried the memories and traumas of war alongside a newfound openness to the states and countries they'd visited. Katie's Great-Uncle Nick visited California's wisteria and jasmine covered coasts before the war, and yearned to go back afterwards. Katie's Grandmother Marie Ventresca visited Los Angeles, La Jolla, and San Diego in the 1930's and 1940's, paving the way for a life-long love of California for the Ventresca Masciopinto family. William proposed to Katie during a weekend trip to the La Valencia hotel in La Jolla, California at sunset on March 20th, 2022. 
        </div>
        <br/>
        <div>
          Heels click-clacked down Michigan Avenue as the 1950's blossomed. Will's Grandmother, Janet Mary Ford, worked at the Marshall Field's in Old Orchard, hiding fabulous packages of dresses & cashmere sweaters from her older sister Kathy. Fashion ran in the family. William's Grandmother, Hope Rudnick, managed Stanley Korshak's, helping Chicago's well-heeled women find their style and their confidence. 
        </div>
        <StyledLink
          url="https://collections.carli.illinois.edu/digital/collection/chm_museum/id/2337"
          text="This dress"
        />
        <div>
          was purchased at Stanley Korshak's, and worn by model Mrs. Ellen Ross for the 1988 Chicago History Museum's Costume Council's Donors Ball and Fashion Show. 
        </div>
        <br/>
        <div>
          Carpenters, lawyers, butchers, keepers of the house, teachers, insurance agents, mobsters (the lawyers), sailors, fashionistas, canoers, skiers, botanists, historians, naturalists, are a few words that can be used to describe the countless family members who have impacted William & Katie, directly and indirectly. 
        </div>
        <br/>
        <div>
          William & Katie fell in love sharing the stories of those who came before, those who will be with us on October 28th, 2023, and those who will be with us in the days, months, and years to come. We are honored to share our story with you, and we look forward to celebrating with you and sharing more of these stories at our wedding. We chose the Chicago History Museum as our venue because it honors not only our ancestry, but the people and their stories, written and unwritten, who have built this city of Broad Shoulders. 
        </div>
        <br/>
        <div>
          Hog Butcher for the World,
        </div>
        <div>
          Tool Maker, Stacker of Wheat,
        </div>
        <div>
          Player with Railroads and the Nation's Freight Handler,
        </div>
        <div>
          Stormy, husky, brawling
        </div>
        <div>
          City of the Big Shoulders:
        </div>
        <br/>
        <div>
          — Carl Sandburg, Chicago (1914)
        </div>
      </Section>
    </>
  );
}

export default Welcome
