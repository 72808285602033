import React, { useRef, useState } from 'react';

function WeddingPartyBubble({
  children,
  header_image,
  person,
  pronouns,
  title
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  
  const scollToRef = useRef();

  const toggleIsCollapsed = (e) => {
    e.preventDefault();

    isCollapsed && scollToRef.current.scrollIntoView(
      {
        behavior:"smooth",
        block: "start",
        inline:"nearest"
      }  
    );

    setIsCollapsed(!isCollapsed)
  }

  const renderChildren = () => {
    return(
      <div className="weddingPartyContent">
        {children}
      </div>
    )
  }

  const className = isCollapsed ? "weddingPartyBubble" : "weddingPartyBubbleExpanded";

  return (
    <>
      <div className={className} ref={scollToRef}>
        <div className="weddingPartyHeader" onClick={toggleIsCollapsed}>
          <img
              alt={`${person}`}
              className="weddingPartyImage"
              src={header_image}
            />
          <div className="weddingPartyName">
            {person}
          </div>
          <div className="weddingPartyPronouns">
            {pronouns}
          </div>
          <div className="weddingPartyTitle">
            {title}
          </div>
        </div>
        <div>
          { isCollapsed
            ? <></>
            : renderChildren()
          }
        </div>
      </div>
    </>
  )
}

export default WeddingPartyBubble
