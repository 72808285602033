import './App.scss';
import LoveDispenser from './components/loveDispenser';
import Welcome from './components/welcome';

function App() {
  return (
    <div className="App">
      <LoveDispenser />
      <Welcome />
    </div>
  );
}

export default App;
