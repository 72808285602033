import React, { useRef, useState } from 'react';

function Section({
  children,
  collapsible,
  collapsed,
  header,
}) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const scollToRef = useRef();

  const toggleIsCollapsed = (e) => {
    e.preventDefault();

    isCollapsed && scollToRef.current.scrollIntoView(
      {
        behavior:"smooth",
        block: "start",
        inline:"nearest"
      }  
    );

    setIsCollapsed(!isCollapsed)
  }

  const renderChildren = () => {
    return(
      <div className="body">
      {children}
      </div>
    )
  }

  if (!collapsible) {
    return (
      <div className="section">
        { renderChildren() }
      </div>
    )
  } else {
    return (
      <div className="section" ref={scollToRef}>
        <div className="headerText" onClick={toggleIsCollapsed}>
          {header}
        </div>
        { isCollapsed
          ? <></>
          : renderChildren()
        }
      </div>
    )
  }
}

export default Section
