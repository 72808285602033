import React, { useCallback } from "react"
import Particles from "react-particles";
import { loadFull } from "tsparticles";

function LoveDispenser() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <>
      <Particles
        className="LoveDispenser"
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 10,
              },
            },
          },
          particles: {
            collisions: {
              enable: true,
            },
            opacity: {
              animation: {
                enable: true,
                speed: 0.3,
                sync: true,
                startValue: "max",
                count: 1,
                destroy: "min"
              },
              value: {
                min: 0,
                max: 1
              }
            },
            move: {
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 5,
              straight: false,
            },
            number: {
              value: 0,
            },
            shape: {
              type: "character",
              character: [
                {
                  value: "❤️‍🔥"
                },
              ],
            },
            size: {
              value: { min: 15, max: 25 },
            },
            zIndex: {
              value: 0
            },
          },
          detectRetina: true,
        }}
        />
    </>
  );
}

export default LoveDispenser
